<template>
  <div
    class="footer flex-between"
    :class="isDay ? 'bg-E9EAEF' : 'bg-black-color'"
  >
    <img :src="isDay ? logoBlack : logo" alt="" class="logo hidden-xs" />
    <div class="item">Copyright 2023 星空準則有限公司</div>
  </div>
</template>

<script>
export default {
  name: "pcFooter",
  data() {
    return {
      isDay: this.$store.state.isDay,
      logo: require("@/assets/img/starry-logo.png"),
      logoBlack: require("@/assets/img/starry-logo-black.png"),
    };
  },
};
</script>

<style scoped lang='stylus' rel="stylesheet/stylus">
@media (min-width: 781px) {
  .logo {
    width: 189.15px;
    height: 36.98px;
  }

  .footer {
    height: 138px;
    box-sizing: border-box;
    padding: 0 120px;

    .item {
      color: #999;
      font-size: 22px;
      cursor: pointer;
    }
  }
}

@media (max-width: 780px) {
  .footer {
    padding: 0.15rem 0.15rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .item {
      color: #999;
      font-size: 0.13rem;
    }
  }
}
</style>
